import { useQuery } from 'react-query';

import { apiRoutes } from 'apiRoutes';
import { QueryKeysEnum } from 'utils/constants';
import { getRequest } from 'helpers';

export interface PayoutCollectionTemplatePropertiesRequestInterface {
  customerUrl: string;
  projectUrl?: string;
}

export const usePayoutCollectionTemplatePropertiesRequest = ({
  customerUrl,
  projectUrl,
}: PayoutCollectionTemplatePropertiesRequestInterface) => {
  return useQuery(
    [QueryKeysEnum.PAYOUT_COLLECTION_TEMPLATE_REQUEST, customerUrl, projectUrl],
    () =>
      getRequest(
        `${
          apiRoutes.PAYOUT_COLLECTION_TEMPLATE_PROPERTIES
        }?customerUrl=${customerUrl}${
          projectUrl ? `&projectUrl=${projectUrl}` : ''
        }`,
      ),
    {
      enabled: !!customerUrl,
    },
  );
};
