import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ExpiredTokenPage, PageLoader } from '@billon/ui';

// Utils
import { appRoutes } from './appRoutes';
import { lazyRetry } from 'helpers';

// Components
import { CorporateAccountThemeInterface, CustomerLogo } from 'components';
import { useTheme } from 'styled-components';

// Pages
const PayoutCollection = lazy(() =>
  lazyRetry(() => import('./pages/PayoutCollection')),
);
const PayinBarCodeView = lazy(() =>
  lazyRetry(() => import('./pages/PayinBarCodeView')),
);
const Login = lazy(() => lazyRetry(() => import('./pages/Login')));

export const UnauthorizedApp = () => {
  const theme = useTheme() as CorporateAccountThemeInterface;
  return (
    <Suspense fallback={<PageLoader minHeight="90vh" />}>
      <Switch>
        <Route path={appRoutes.HOME_PAGE_PANEL} component={Login} />
        <Route
          path={appRoutes.TOKEN_EXPIRED}
          render={() => (
            <ExpiredTokenPage
              pathname={appRoutes.HOME_PAGE_PANEL}
              brand={
                theme.logoUrl ? <CustomerLogo src={theme.logoUrl} /> : undefined
              }
            />
          )}
        />
        <Route
          path={appRoutes.PAYIN_BARCODE_VIEW}
          component={PayinBarCodeView}
        />
        <Route
          path={appRoutes.PAYOUT_COLLECTION}
          component={PayoutCollection}
        />
      </Switch>
    </Suspense>
  );
};
