import { ReactNode } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { UseMutationResult, useQueryClient } from 'react-query';
import { SMSAuthorizationModal, useAuthorizedRequest } from '@billon/ui';

export interface RequestResponseInterface extends AxiosResponse {
  authorizationRequired?: boolean;
}

export interface SMSAuthorizationFormContainerProps<RequestType, ResponseType> {
  request: (
    onSuccess: (response: ResponseType) => void,
    onError: (err: AxiosError) => void,
    invalidateQueries: string[],
  ) => UseMutationResult<ResponseType, AxiosError, RequestType, unknown>;
  errorHandler?: (errors: AxiosError) => void;
  onSuccess?: () => void;
  invalidateQueries?: string[];
  children: (
    save: (
      values: RequestType,
      saveFunc: (values: RequestType) => void,
    ) => void,
    mutationResult: UseMutationResult<ResponseType, AxiosError, RequestType>,
  ) => ReactNode;
}

export function SMSAuthorizationFormContainer<
  RequestType,
  ResponseType extends RequestResponseInterface = RequestResponseInterface,
>({
  request,
  errorHandler,
  onSuccess = () => {},
  invalidateQueries = [],
  children,
}: SMSAuthorizationFormContainerProps<RequestType, ResponseType>) {
  const queryClient = useQueryClient();

  const {
    save,
    resend,
    onSuccess: requestOnSuccess,
    isAuthorizeModalOpen,
    closeModal,
    isError: isAuthorizationError,
    onError,
  } = useAuthorizedRequest<RequestType>(onSuccess);

  const mutationResult = request(
    (response?: ResponseType) => {
      if (isAuthorizeModalOpen && response?.authorizationRequired) {
        onError(true);
        return;
      }

      if (!response?.authorizationRequired) {
        invalidateQueries.forEach((queryName) => {
          queryClient.invalidateQueries(queryName);
        });
      }

      requestOnSuccess(response);
    },
    (e) => {
      closeModal();
      if (errorHandler) {
        errorHandler(e);
      }
    },
    invalidateQueries,
  );

  return (
    <>
      {children(save, mutationResult)}

      <SMSAuthorizationModal
        onSubmit={({ verificationCode }) => resend(verificationCode)}
        isOpen={isAuthorizeModalOpen}
        toggle={closeModal}
        isLoading={mutationResult.isLoading}
        isError={isAuthorizationError}
      />
    </>
  );
}
