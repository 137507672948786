import { useQuery } from 'react-query';

import { apiRoutes } from 'apiRoutes';
import { QueryKeysEnum } from 'utils/constants';
import { getRequest } from 'helpers';

export const useCustomerSettingsRequest = () => {
  return useQuery(QueryKeysEnum.CUSTOMER_SETTINGS_REQUEST, () =>
    getRequest(apiRoutes.CUSTOMER_SETTINGS),
  );
};
