import { useTheme } from 'styled-components';

import { useAuthContext } from '@billon/ui';
import { CorporateAccountThemeInterface } from 'components';

export const usePayoutCollectionPageLinkOptions = (projectUrl?: string) => {
  const { user } = useAuthContext();
  const theme = useTheme() as CorporateAccountThemeInterface;

  const payoutCollectionPageLinkOptions: string[] = [window.location.origin];

  if (theme.customDomainName) {
    payoutCollectionPageLinkOptions.push(theme.customDomainName);
  }

  if (user.customer.url) {
    payoutCollectionPageLinkOptions.push(
      `${window.location.origin}/${user.customer.url}`,
    );
  }

  if (projectUrl) {
    payoutCollectionPageLinkOptions.push(
      `${window.location.origin}/${user.customer.url}/${projectUrl}`,
    );
  }

  return { payoutCollectionPageLinkOptions };
};
