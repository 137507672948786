import { Suspense, lazy } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  AuthContextProvider,
  ProtectedApplication,
  ConfigContextProvider,
  PageLoader,
  ProtectedRoute,
  TechnicalBreak,
  LanguageContextProvider,
  IntlProvider,
} from '@billon/ui';
import { PermissionsEnum } from 'corporate-account-shared';

import { UnauthorizedApp } from './UnauthorizedApp';

// Languages
import enMessages from './languages/en.json';
import plMessages from './languages/pl.json';

// Utils
import config from './config';
import { apiRoutes } from './apiRoutes';
import { appRoutes } from './appRoutes';
import { lazyRetry } from 'helpers';

// Components
import { Layout, CorporateAccountThemeProvider } from './components';

// Pages
const HomePage = lazy(() => lazyRetry(() => import('./pages/HomePage')));
const UnverifiedUser = lazy(() =>
  lazyRetry(() => import('./pages/UnverifiedUser')),
);
const AccountDetails = lazy(() =>
  lazyRetry(() => import('./pages/AccountDetails')),
);
const Recipients = lazy(() => lazyRetry(() => import('./pages/Recipients')));
const RecipientCreationEdition = lazy(() =>
  lazyRetry(() => import('./pages/RecipientCreationEdition')),
);
const Payins = lazy(() => lazyRetry(() => import('./pages/Payins')));
const PayinBarCodes = lazy(() =>
  lazyRetry(() => import('./pages/PayinBarCodes')),
);
const Payers = lazy(() => lazyRetry(() => import('./pages/Payers')));
const PayerCreationEdition = lazy(() =>
  lazyRetry(() => import('./pages/PayerCreationEdition')),
);
const Payouts = lazy(() => lazyRetry(() => import('./pages/Payouts')));
const PayoutCreation = lazy(() =>
  lazyRetry(() => import('./pages/PayoutCreation')),
);
const PayoutDuplication = lazy(() =>
  lazyRetry(() => import('./pages/PayoutDuplication')),
);
const Projects = lazy(() => lazyRetry(() => import('./pages/Projects')));
const ProjectCreationEdition = lazy(() =>
  lazyRetry(() => import('./pages/ProjectCreationEdition')),
);
const Beneficiaries = lazy(() =>
  lazyRetry(() => import('./pages/Beneficiaries')),
);
const BeneficiaryCreationEdition = lazy(() =>
  lazyRetry(() => import('./pages/BeneficiaryCreationEdition')),
);
const Consumers = lazy(() => lazyRetry(() => import('./pages/Consumers')));
const ConsumerCreationEdition = lazy(() =>
  lazyRetry(() => import('./pages/ConsumerCreationEdition')),
);
const Users = lazy(() => lazyRetry(() => import('./pages/Users')));
const UserCreationEdition = lazy(() =>
  lazyRetry(() => import('./pages/UserCreationEdition')),
);
const Accounts = lazy(() => lazyRetry(() => import('./pages/Accounts')));
const AccountCreationEdition = lazy(() =>
  lazyRetry(() => import('./pages/AccountCreationEdition')),
);
const MakeTransfer = lazy(() =>
  lazyRetry(() => import('./pages/MakeTransfer')),
);
const Commissions = lazy(() => lazyRetry(() => import('./pages/Commissions')));
const CommissionCreationEdition = lazy(() =>
  lazyRetry(() => import('./pages/CommissionCreationEdition')),
);
const Customers = lazy(() => lazyRetry(() => import('./pages/Customers')));
const CustomerCreationEdition = lazy(() =>
  lazyRetry(() => import('./pages/CustomerCreationEdition')),
);
const CustomersSettings = lazy(() =>
  lazyRetry(() => import('./pages/CustomersSettings')),
);
const Settings = lazy(() => lazyRetry(() => import('./pages/Settings')));

const messages: { [name: string]: any } = {
  en: enMessages,
  pl: plMessages,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      suspense: false,
    },
  },
});

if (config.sentryDSN) {
  Sentry.init({
    dsn: config.sentryDSN,
    environment: config.sentryEnvironment,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ConfigContextProvider value={config}>
      <LanguageContextProvider
        availableLanguages={config.availableLanguages as string[]}
      >
        <AuthContextProvider>
          <CorporateAccountThemeProvider>
            {(url?: string) => (
              <IntlProvider messages={messages}>
                <Router basename={`/${url}`}>
                  <TechnicalBreak statusUrl={apiRoutes.STATUS}>
                    <ProtectedApplication
                      meUrl={apiRoutes.ME}
                      unauthorizedApp={() => <UnauthorizedApp />}
                      tokenExpiredPath={appRoutes.TOKEN_EXPIRED}
                    >
                      <Layout>
                        <Suspense fallback={<PageLoader minHeight="70vh" />}>
                          <Switch>
                            <ProtectedRoute
                              resource={[
                                PermissionsEnum.BANK_TRANSFER_MANAGE,
                                PermissionsEnum.PEER_TO_PEER_MANAGE,
                              ]}
                              path={appRoutes.ACCOUNT_MAKE_TRANSFER}
                              component={MakeTransfer}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.ACCOUNTS_MANAGE}
                              path={appRoutes.ACCOUNT_CREATION}
                              component={AccountCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.ACCOUNTS_MANAGE}
                              path={appRoutes.ACCOUNT_EDITION}
                              component={AccountCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.ACCOUNTS}
                              path={appRoutes.ACCOUNTS}
                              component={Accounts}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.ACCOUNTS}
                              path={appRoutes.ACCOUNT_DETAILS}
                              component={AccountDetails}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYIN_PAYER_MANAGE}
                              path={appRoutes.PAYIN_PAYER_CREATION}
                              component={PayerCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYIN_PAYER_MANAGE}
                              path={appRoutes.PAYIN_PAYER_EDITION}
                              component={PayerCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYIN_PAYER_READ}
                              path={appRoutes.PAYIN_PAYERS}
                              component={Payers}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYIN_BAR_CODE_READ}
                              path={appRoutes.PAYIN_BAR_CODES}
                              component={PayinBarCodes}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYIN_READ}
                              path={appRoutes.PAYIN_HOME_PAGE}
                              component={Payins}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYOUT_RECIPIENT_MANAGE}
                              path={appRoutes.PAYOUT_RECIPIENTS_CREATION}
                              component={RecipientCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYOUT_RECIPIENT_MANAGE}
                              path={appRoutes.PAYOUT_RECIPIENTS_EDITION}
                              component={RecipientCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYOUT_RECIPIENT_READ}
                              path={appRoutes.PAYOUT_RECIPIENTS}
                              component={Recipients}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYOUT_PROJECT_MANAGE}
                              path={appRoutes.PAYOUT_PROJECT_CREATION}
                              component={ProjectCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYOUT_PROJECT_MANAGE}
                              path={appRoutes.PAYOUT_PROJECT_EDITION}
                              component={ProjectCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYOUT_PROJECT_MANAGE}
                              path={appRoutes.PAYOUT_PROJECTS}
                              component={Projects}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYOUT_PROJECT_READ}
                              path={appRoutes.PAYOUT_PROJECTS}
                              component={Projects}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYOUT_MANAGE}
                              path={appRoutes.PAYOUT_CREATION}
                              component={PayoutCreation}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYOUT_MANAGE}
                              path={appRoutes.PAYOUT_DUPLICATION}
                              component={PayoutDuplication}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.PAYOUT_READ}
                              path={appRoutes.PAYOUT_HOME_PAGE}
                              component={Payouts}
                            />
                            <ProtectedRoute
                              resource={
                                PermissionsEnum.BANK_TRANSFER_BENEFICIARY_MANAGE
                              }
                              path={appRoutes.BENEFICIARY_CREATION}
                              component={BeneficiaryCreationEdition}
                            />
                            <ProtectedRoute
                              resource={
                                PermissionsEnum.BANK_TRANSFER_BENEFICIARY_MANAGE
                              }
                              path={appRoutes.BENEFICIARY_EDITION}
                              component={BeneficiaryCreationEdition}
                            />
                            <ProtectedRoute
                              resource={
                                PermissionsEnum.BANK_TRANSFER_BENEFICIARY_READ
                              }
                              path={appRoutes.BENEFICIARIES}
                              component={Beneficiaries}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.CONSUMER_MANAGE}
                              path={appRoutes.CONSUMER_CREATION}
                              component={ConsumerCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.CONSUMER_MANAGE}
                              path={appRoutes.CONSUMER_EDITION}
                              component={ConsumerCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.CONSUMER_READ}
                              path={appRoutes.CONSUMERS}
                              component={Consumers}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.USER_MANAGE}
                              path={appRoutes.USER_CREATION}
                              component={UserCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.USER_MANAGE}
                              path={appRoutes.USER_EDITION}
                              component={UserCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.USER_READ}
                              path={appRoutes.USERS}
                              component={Users}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.COMMISSION_MANAGE}
                              path={appRoutes.COMMISSION_CREATION}
                              component={CommissionCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.COMMISSION_MANAGE}
                              path={appRoutes.COMMISSION_EDITION}
                              component={CommissionCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.COMMISSION_READ}
                              path={appRoutes.COMMISSIONS}
                              component={Commissions}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.CUSTOMER_SETTINGS}
                              path={appRoutes.CUSTOMERS_SETTINGS}
                              component={CustomersSettings}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.CUSTOMER_MANAGE}
                              path={appRoutes.CUSTOMER_CREATION}
                              component={CustomerCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.CUSTOMER_MANAGE}
                              path={appRoutes.CUSTOMER_EDITION}
                              component={CustomerCreationEdition}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.CUSTOMER_READ}
                              path={appRoutes.CUSTOMERS}
                              component={Customers}
                            />
                            <ProtectedRoute
                              resource={PermissionsEnum.SETTINGS_MANAGE}
                              path={appRoutes.SETTINGS}
                              component={Settings}
                            />
                            <Route
                              path={appRoutes.UNVERIFIED_USER}
                              component={UnverifiedUser}
                            />
                            <Route
                              path={appRoutes.HOME_PAGE_PANEL}
                              component={HomePage}
                            />
                          </Switch>
                        </Suspense>
                      </Layout>
                    </ProtectedApplication>
                  </TechnicalBreak>
                </Router>
              </IntlProvider>
            )}
          </CorporateAccountThemeProvider>
        </AuthContextProvider>
      </LanguageContextProvider>
    </ConfigContextProvider>
  </QueryClientProvider>
);

export default App;
