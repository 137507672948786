import { CurrencyCodesEnum } from '@billon/utils';

// Hooks
import { useSettingsRequest } from 'hooks';

// Enums
import { SettingsEnum } from 'utils/constants';

export const useAvailableCurrenciesOptions = () => {
  const { isFetching, data: settings } = useSettingsRequest();

  let currenciesOptions: CurrencyCodesEnum[] = [];

  if (!isFetching && settings) {
    currenciesOptions = JSON.parse(settings[SettingsEnum.AVAILABLE_CURRENCIES]);
  }

  return { currenciesOptions, isFetching };
};
