import {
  PayoutCollectionDefaultSettingsEnum,
  PayoutCollectionPageBackgroundType,
  PayoutCollectionPageHeaderType,
} from 'corporate-account-shared';

export interface DefaultPayoutCollectionPropertiesInterface {
  collectionPageBackgroundType: PayoutCollectionPageBackgroundType;
  collectionPageHeaderType: PayoutCollectionPageHeaderType;
  collectionPageWebTitle: PayoutCollectionDefaultSettingsEnum;
  collectionPageBackgroundColor: string;
  collectionPageTitle: PayoutCollectionDefaultSettingsEnum;
  collectionPageSupportPageLink: PayoutCollectionDefaultSettingsEnum;
  collectionPageSupportPageLinkText: PayoutCollectionDefaultSettingsEnum;
  collectionPageNoPayoutsMessage: PayoutCollectionDefaultSettingsEnum;
  collectionPageSelectCurrencyTitle: PayoutCollectionDefaultSettingsEnum;
  collectionPageSuccessMessage: PayoutCollectionDefaultSettingsEnum;
}

export const useDefaultPayoutCollectionProperties =
  (): DefaultPayoutCollectionPropertiesInterface => {
    return {
      collectionPageBackgroundType: PayoutCollectionPageBackgroundType.IMAGE,
      collectionPageHeaderType: PayoutCollectionPageHeaderType.TITLE,
      collectionPageWebTitle:
        PayoutCollectionDefaultSettingsEnum.PAYOUT_COLLECTION_PAGE_WEB_TITLE,
      collectionPageBackgroundColor: '',
      collectionPageTitle:
        PayoutCollectionDefaultSettingsEnum.PAYOUT_COLLECTION_PAGE_TITLE,
      collectionPageSupportPageLink:
        PayoutCollectionDefaultSettingsEnum.PAYOUT_COLLECTION_PAGE_SUPPORT_PAGE_LINK,
      collectionPageSupportPageLinkText:
        PayoutCollectionDefaultSettingsEnum.PAYOUT_COLLECTION_PAGE_SUPPORT_PAGE_LINK_TEXT,
      collectionPageNoPayoutsMessage:
        PayoutCollectionDefaultSettingsEnum.PAYOUT_COLLECTION_PAGE_NO_PAYUOTS_MESSAGE,
      collectionPageSelectCurrencyTitle:
        PayoutCollectionDefaultSettingsEnum.PAYOUT_COLLECTION_PAGE_SELECT_PAYOUTS_TITLE,
      collectionPageSuccessMessage:
        PayoutCollectionDefaultSettingsEnum.PAYOUT_COLLECTION_PAGE_SUCCESS_MESSAGE,
    };
  };
