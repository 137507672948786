import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-awesome-styled-grid';
import { Button, ModalFooter, SuccessContent } from '@billon/ui';

interface SuccessInfoProps {
  children: React.ReactNode;
  onClose: () => void;
}

export const SuccessInfo = ({ children, onClose }: SuccessInfoProps) => (
  <>
    <SuccessContent>{children}</SuccessContent>
    <ModalFooter>
      <Row justify="center">
        <Col md={6} lg={8}>
          <Button onClick={onClose} size="lg" block color="primary">
            <FormattedMessage id="Ok" defaultMessage="Ok" />
          </Button>
        </Col>
      </Row>
    </ModalFooter>
  </>
);
