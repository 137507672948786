import {
  getRequest as billonUiGetRequest,
  postRequest as billonUiPostRequest,
  putRequest as billonUiPutRequest,
  deleteRequest as billonUiDeleteRequest,
  loadToken,
} from '@billon/ui';

export const getRequest = (
  url: string,
  options?: Record<string, any>,
  headers?: Record<string, any>,
) => {
  const token = loadToken();
  return billonUiGetRequest(url, token, options, headers);
};

export const postRequest = (
  url: string,
  params?: Record<string, any>,
  headers?: Record<string, any>,
) => {
  const token = loadToken();
  return billonUiPostRequest(url, token, params, headers);
};

export const putRequest = (
  url: string,
  params?: Record<string, any>,
  headers?: Record<string, any>,
) => {
  const token = loadToken();
  return billonUiPutRequest(url, token, params, headers);
};

export const deleteRequest = (
  url: string,
  params?: Record<string, any>,
  headers?: Record<string, any>,
) => {
  const token = loadToken();
  return billonUiDeleteRequest(url, token, params, headers);
};

export const lazyRetry = function (componentImport) {
  return new Promise<{ default: React.ComponentType<any> }>(
    (resolve, reject) => {
      const hasRefreshed = JSON.parse(
        window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
      );

      componentImport()
        .then((component) => {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
          resolve(component);
        })
        .catch((error) => {
          if (!hasRefreshed) {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
            return window.location.reload();
          }
          reject(error);
        });
    },
  );
};
