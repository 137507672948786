import env from './env';
import { configs } from './config/index';
import localConfig from './config/local';
import { CorporateAccountConfigInterface } from './config/base';

let config: CorporateAccountConfigInterface = configs[env];

if (env === 'dev') {
  config = {
    ...config,
    ...localConfig,
  };
}

export default config;
