import { CorporateAccountConfigInterface } from './base';
import corporateaccountdemo from './corporateaccountdemo';
import plcorporateaccount from './plcorporateaccount';
import ukcorporateaccount from './ukcorporateaccount';
import hestia from './hestia';
import corporateaccountuat from './corporateaccountuat';

export const configs: Record<string, CorporateAccountConfigInterface> = {
  corporateaccountuat,
  corporateaccountdemo,
  plcorporateaccount,
  ukcorporateaccount,
  hestia,
};
