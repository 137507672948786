import { useQuery } from 'react-query';

import { apiRoutes } from 'apiRoutes';
import { QueryKeysEnum } from 'utils/constants';
import { getRequest } from 'helpers';

export const useCountryRequest = () => {
  return useQuery([QueryKeysEnum.PAYMENT_ROUTES_COUNTRIES], () =>
    getRequest(apiRoutes.PAYMENT_ROUTES_COUNTRIES),
  );
};
