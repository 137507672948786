import { FormattedMessage } from 'react-intl';

export interface AccountNameProps {
  name?: string;
}

export const AccountName = ({ name }: AccountNameProps) => (
  <>
    {name || (
      <FormattedMessage
        id="Digital cash account"
        defaultMessage="Digital cash account"
      />
    )}
  </>
);
