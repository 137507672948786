import { useIntl } from 'react-intl';
import { ControllerRenderProps } from 'react-hook-form';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  insertVariable,
} from '@billon/ui';

// Enums
import { SMSMessageVariablesEnum } from 'corporate-account-shared';

// Components
import { SMSMessageVariablesContainer } from 'components/SMSMessageVariablesContainer';

// Hooks
import { usePayoutCollectionPageLinkOptions } from 'hooks';

export interface PayoutSMSMessageVariablesProps<T> {
  field: ControllerRenderProps<T>;
  disabled: boolean;
  projectUrl?: string;
}

export function PayoutSMSMessageVariables<T>({
  field,
  disabled,
  projectUrl,
}: PayoutSMSMessageVariablesProps<T>) {
  const { formatMessage } = useIntl();
  const { payoutCollectionPageLinkOptions } =
    usePayoutCollectionPageLinkOptions(projectUrl);

  const handleChooseCollectionLink = (
    link: string,
    closeDropdown: () => void,
  ) => {
    insertVariable(field, link);
    closeDropdown();
  };

  return (
    <SMSMessageVariablesContainer>
      <Button
        onClick={() =>
          insertVariable(field, SMSMessageVariablesEnum.PHONE_NUMBER)
        }
        size="sm"
        color="primary"
        outline
        disabled={disabled}
      >
        {formatMessage({
          id: "Recipient's Phone number",
          defaultMessage: "Recipient's Phone number",
        })}
      </Button>
      <Button
        onClick={() =>
          insertVariable(field, SMSMessageVariablesEnum.PROJECT_NAME)
        }
        size="sm"
        color="primary"
        outline
        disabled={disabled}
      >
        {formatMessage({
          id: 'Project name',
          defaultMessage: 'Project name',
        })}
      </Button>
      <Button
        onClick={() =>
          insertVariable(field, SMSMessageVariablesEnum.PAYOUT_REFERENCE)
        }
        size="sm"
        color="primary"
        outline
        disabled={disabled}
      >
        {formatMessage({
          id: 'Payout reference',
          defaultMessage: 'Payout reference',
        })}
      </Button>
      <Button
        onClick={() =>
          insertVariable(field, SMSMessageVariablesEnum.PAYOUT_AMOUNT)
        }
        size="sm"
        color="primary"
        outline
        disabled={disabled}
      >
        {formatMessage({
          id: 'Payout amount',
          defaultMessage: 'Payout amount',
        })}
      </Button>
      <Dropdown
        trigger={
          <Button size="sm" color="primary" outline disabled={disabled}>
            {formatMessage({
              id: 'Payout collection link',
              defaultMessage: 'Payout collection link',
            })}
          </Button>
        }
        position="bottom right"
      >
        {(closeDropdown) => (
          <DropdownMenu>
            {payoutCollectionPageLinkOptions.map((link) => (
              <DropdownItem
                key={link}
                onClick={() => handleChooseCollectionLink(link, closeDropdown)}
              >
                {link}
              </DropdownItem>
            ))}
          </DropdownMenu>
        )}
      </Dropdown>
    </SMSMessageVariablesContainer>
  );
}
