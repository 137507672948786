import { useQuery } from 'react-query';

import { apiRoutes } from 'apiRoutes';
import { QueryKeysEnum } from 'utils/constants';
import { getRequest } from 'helpers';

export const useCustomersRequest = (isSuperAdmin = true) => {
  return useQuery(
    QueryKeysEnum.CUSTOMERS_REQUEST,
    () => getRequest(apiRoutes.CUSTOMER),
    {
      enabled: isSuperAdmin,
    },
  );
};
