import styled from 'styled-components';
import { ReactNode } from 'react';

// Components
import { Header, Footer } from './components';

export interface LayoutProps {
  children: ReactNode;
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex-grow: 1;
`;

export const Layout = ({ children }: LayoutProps) => (
  <Wrapper>
    <Header />
    <Content>{children}</Content>
    <Footer />
  </Wrapper>
);
