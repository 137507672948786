import { basicTheme, ConfigInterface } from '@billon/ui';
import { CorporateAccountThemeInterface } from '../components';

export interface CorporateAccountConfigInterface extends ConfigInterface {
  limitOptions: number[];
  language: string;
  theme: CorporateAccountThemeInterface;
  backendUrl: string;
  kycFrontendUrl: string;
  preferredPhoneNumberCountries: string[];
  sentryDSN?: string;
  sentryEnvironment?: string;
}

const config: Omit<
  CorporateAccountConfigInterface,
  'backendUrl' | 'kycFrontendUrl'
> = {
  limit: 10,
  limitOptions: [10, 20, 50],
  availableLanguages: ['pl', 'en'],
  language: 'en',
  preferredPhoneNumberCountries: ['pl'],
  theme: basicTheme,
  billonLogoUrl: 'https://billongroup.com/billon-solutions',
};

export default config;
