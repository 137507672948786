import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { apiRoutes } from 'apiRoutes';
import { QueryKeysEnum } from 'utils/constants';
import { getRequest } from 'helpers';

export const useAccountRequest = () => {
  const { id } = useParams<{ id: string }>();

  return useQuery(
    [QueryKeysEnum.ACCOUNT_REQUEST, id],
    () => getRequest(apiRoutes.SINGLE_ACCOUNT.replace('{id}', id)),
    {
      enabled: !!id,
    },
  );
};
