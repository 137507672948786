import { useQuery } from 'react-query';

import { apiRoutes } from 'apiRoutes';
import { QueryKeysEnum } from 'utils/constants';
import { getRequest } from 'helpers';

export const useSettingsRequest = () => {
  return useQuery(QueryKeysEnum.SETTINGS_REQUEST, () =>
    getRequest(apiRoutes.SETTINGS),
  );
};
