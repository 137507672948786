import styled from 'styled-components';

export const Header = styled.h4`
  margin: 1rem 0;

  &:after {
    content: '';
    width: 5rem;
    display: block;
    height: 0.2rem;
    background: ${({ theme }) => theme.palette.primary};
  }
`;
