export const appRoutes = {
  HOME_PAGE_PANEL: '/panel',
  UNVERIFIED_USER: '/panel/unverified-user',
  ACCOUNTS: '/panel/accounts',
  ACCOUNT_CREATION: '/panel/accounts/create',
  ACCOUNT_EDITION: '/panel/accounts/:id/update',
  ACCOUNT_MAKE_TRANSFER: '/panel/accounts/:id/transfer',
  USERS: '/panel/users',
  USER_CREATION: '/panel/users/create',
  USER_EDITION: '/panel/users/:id/update',
  ACCOUNT_DETAILS: '/panel/account-details/:id',
  PAYIN_HOME_PAGE: '/panel/payins',
  PAYIN_PAYERS: '/panel/payins/payers',
  PAYIN_PAYER_CREATION: '/panel/payins/payers/create',
  PAYIN_PAYER_EDITION: '/panel/payins/payers/:id/update',
  PAYIN_BAR_CODES: '/panel/payins/bar-codes',
  PAYIN_BARCODE_VIEW: '/barcode/:barcode',
  PAYOUT_HOME_PAGE: '/panel/payouts',
  PAYOUT_CREATION: '/panel/payouts/create',
  PAYOUT_DUPLICATION: '/panel/payouts/:id/duplicate',
  PAYOUT_PROJECTS: '/panel/payouts/projects',
  PAYOUT_PROJECT_CREATION: '/panel/payouts/projects/create',
  PAYOUT_PROJECT_EDITION: '/panel/payouts/projects/:id/update',
  PAYOUT_RECIPIENTS: '/panel/payouts/recipients',
  PAYOUT_RECIPIENTS_CREATION: '/panel/payouts/recipients/create',
  PAYOUT_RECIPIENTS_EDITION: '/panel/payouts/recipients/:id/update',
  PAYOUT_COLLECTION: '/',
  COMMISSIONS: '/panel/commissions',
  COMMISSION_CREATION: '/panel/commissions/create',
  COMMISSION_EDITION: '/panel/commissions/:id/update',
  CUSTOMERS: '/panel/customers',
  CUSTOMER_CREATION: '/panel/customers/create',
  CUSTOMER_EDITION: '/panel/customers/:id/update',
  CUSTOMERS_SETTINGS: '/panel/customers/settings',
  BENEFICIARIES: '/panel/beneficiaries',
  BENEFICIARY_CREATION: '/panel/beneficiaries/create',
  BENEFICIARY_EDITION: '/panel/beneficiaries/:id/update',
  CONSUMERS: '/panel/consumers',
  CONSUMER_CREATION: '/panel/consumers/create',
  CONSUMER_EDITION: '/panel/consumers/:id/update',
  SETTINGS: '/panel/settings',
  TOKEN_EXPIRED: '/token-expired',
};
