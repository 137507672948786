import styled from 'styled-components';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

const StyledContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  label {
    margin: 0;
  }
`;

export interface SMSMessageVariablesContainerProps {
  children: ReactNode;
}

export const SMSMessageVariablesContainer = ({
  children,
}: SMSMessageVariablesContainerProps) => (
  <StyledContainer>
    <label>
      <FormattedMessage
        id="Available variables"
        defaultMessage="Available variables"
      />
      :
    </label>
    {children}
  </StyledContainer>
);
