import { useQuery, UseQueryOptions } from 'react-query';
import { CountryCodesEnum, CurrencyCodesEnum } from '@billon/utils';

import { apiRoutes } from 'apiRoutes';
import { QueryKeysEnum } from 'utils/constants';
import { getRequest } from 'helpers';

import { TransactionDetailsFieldsEnum } from '@billon/utils';

export const usePaymentRequiredFieldsRequest = (
  countryCode?: CountryCodesEnum,
  currency?: CurrencyCodesEnum,
  options: UseQueryOptions<TransactionDetailsFieldsEnum[]> = {},
) =>
  useQuery<TransactionDetailsFieldsEnum[]>(
    [QueryKeysEnum.PAYMENT_ROUTES_REQUIRED_FIELDS, countryCode, currency],
    () =>
      getRequest(
        `${apiRoutes.PAYMENT_ROUTES_REQUIRED_FIELDS}?countryCode=${countryCode}&currency=${currency}`,
      ),
    {
      ...options,
      enabled: !!(countryCode && currency),
      staleTime: Infinity,
    },
  );
