import base from './base';

export default {
  ...base,
  backendUrl: 'https://apiplcorporateaccount.bybillon.com',
  kycFrontendUrl: 'https://kyc.bybillon.com/',
  sentryDSN:
    'https://ef08cf88e4f848279f2a116b4946b621@o4504949894414336.ingest.sentry.io/4505012195033088',
  sentryEnvironment: 'production',
};
