import { ProjectSettingsEnum } from 'corporate-account-shared';

import {
  CustomerPayoutCollectionPropertiesInterface,
  useCustomerPayoutCollectionProperties,
} from './useCustomerPayoutCollectionProperties';

export interface ProjectPayoutCollectionPropertiesInterface
  extends CustomerPayoutCollectionPropertiesInterface {}

export const useProjectPayoutCollectionProperties = (
  projectSettings,
  projectNotificationMessage,
): ProjectPayoutCollectionPropertiesInterface => {
  const customerProperties = useCustomerPayoutCollectionProperties();

  return {
    payoutSMSNotificationMessage:
      projectNotificationMessage ||
      customerProperties.payoutSMSNotificationMessage,
    collectionPageBackgroundType:
      projectSettings[
        ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_BACKGROUND_TYPE
      ] || customerProperties.collectionPageBackgroundType,
    collectionPageHeaderType:
      projectSettings[ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_HEADER_TYPE] ||
      customerProperties.collectionPageHeaderType,
    collectionPageWebTitle:
      projectSettings[ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_WEB_TITLE] ||
      customerProperties.collectionPageWebTitle,
    collectionPageTitle:
      projectSettings[ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_TITLE] ||
      customerProperties.collectionPageTitle,
    collectionPageHeaderLink:
      projectSettings[ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_HEADER_LINK] ||
      customerProperties.collectionPageHeaderLink,
    collectionPageSupportPageLinkText:
      projectSettings[
        ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_SUPPORT_PAGE_LINK_TEXT
      ] || customerProperties.collectionPageSupportPageLinkText,
    collectionPageSupportPageLink:
      projectSettings[
        ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_SUPPORT_PAGE_LINK
      ] || customerProperties.collectionPageSupportPageLink,
    collectionPageNoPayoutsMessage:
      projectSettings[
        ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_NO_PAYUOTS_MESSAGE
      ] || customerProperties.collectionPageNoPayoutsMessage,
    collectionPageSelectCurrencyTitle:
      projectSettings[
        ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_SELECT_PAYOUTS_TITLE
      ] || customerProperties.collectionPageSelectCurrencyTitle,
    collectionPageSuccessMessage:
      projectSettings[
        ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_SUCCESS_MESSAGE
      ] || customerProperties.collectionPageSuccessMessage,

    collectionPageFavicon:
      projectSettings[ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_FAVICON] ||
      customerProperties.collectionPageFavicon,
    collectionPageBackgroundColor:
      projectSettings[
        ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_BACKGROUND_COLOR
      ] || customerProperties.collectionPageBackgroundColor,
    collectionPageBackgroundImage:
      projectSettings[
        ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_BACKGROUND_IMAGE
      ] || customerProperties.collectionPageBackgroundImage,
    collectionPageLogo:
      projectSettings[ProjectSettingsEnum.PAYOUT_COLLECTION_PAGE_LOGO] ||
      customerProperties.collectionPageLogo,
  };
};
