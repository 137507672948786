import {
  Icon,
  NavbarNav,
  NavbarNavItem,
  NavbarNavItemLink,
  ProtectedComponent,
} from '@billon/ui';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { PermissionsEnum } from 'corporate-account-shared';

// Utils
import { appRoutes } from 'appRoutes';

export const Menu = () => (
  <NavbarNav justify="center">
    <NavbarNavItem
      renderProps={(props: any) => (
        <NavLink
          {...props}
          to={appRoutes.HOME_PAGE_PANEL}
          exact
          component={NavbarNavItemLink}
        />
      )}
    >
      <Icon name="home" />
      <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />
    </NavbarNavItem>

    <ProtectedComponent resource={PermissionsEnum.CUSTOMER_MANAGE}>
      <NavbarNavItem
        renderProps={(props: any) => (
          <NavLink
            to={appRoutes.CUSTOMERS}
            {...props}
            component={NavbarNavItemLink}
          />
        )}
      >
        <Icon name="hand-holding-usd" />
        <FormattedMessage id="Customers" defaultMessage="Customers" />
      </NavbarNavItem>
    </ProtectedComponent>

    <ProtectedComponent resource={PermissionsEnum.USER_MANAGE}>
      <NavbarNavItem
        renderProps={(props: any) => (
          <NavLink
            to={appRoutes.USERS.replace('/:customerId?', '')}
            {...props}
            component={NavbarNavItemLink}
          />
        )}
      >
        <Icon name="users" />
        <FormattedMessage id="Users" defaultMessage="Users" />
      </NavbarNavItem>
    </ProtectedComponent>

    <ProtectedComponent resource={PermissionsEnum.CONSUMER_MANAGE}>
      <NavbarNavItem
        renderProps={(props: any) => (
          <NavLink
            to={appRoutes.CONSUMERS.replace('/:customerId?', '')}
            {...props}
            component={NavbarNavItemLink}
          />
        )}
      >
        <Icon name="user-friends" />
        <FormattedMessage id="Consumers" defaultMessage="Consumers" />
      </NavbarNavItem>
    </ProtectedComponent>

    <ProtectedComponent resource={PermissionsEnum.PAYOUT_MANAGE}>
      <NavbarNavItem
        renderProps={(props: any) => (
          <NavLink
            to={appRoutes.PAYOUT_HOME_PAGE}
            {...props}
            component={NavbarNavItemLink}
          />
        )}
      >
        <Icon name="money-bill-wave" />
        <FormattedMessage id="Payouts" defaultMessage="Payouts" />
      </NavbarNavItem>
    </ProtectedComponent>

    <ProtectedComponent resource={PermissionsEnum.PAYIN_MANAGE}>
      <NavbarNavItem
        renderProps={(props: any) => (
          <NavLink
            to={appRoutes.PAYIN_HOME_PAGE}
            {...props}
            component={NavbarNavItemLink}
          />
        )}
      >
        <Icon name="money-check-alt" />
        <FormattedMessage id="Payins" defaultMessage="Payins" />
      </NavbarNavItem>
    </ProtectedComponent>

    <ProtectedComponent
      resource={PermissionsEnum.BANK_TRANSFER_BENEFICIARY_MANAGE}
    >
      <NavbarNavItem
        renderProps={(props: any) => (
          <NavLink
            to={appRoutes.BENEFICIARIES}
            {...props}
            component={NavbarNavItemLink}
          />
        )}
      >
        <Icon name="hand-holding-usd" />
        <FormattedMessage id="Beneficiaries" defaultMessage="Beneficiaries" />
      </NavbarNavItem>
    </ProtectedComponent>
    <ProtectedComponent resource={PermissionsEnum.CUSTOMER_SETTINGS}>
      <NavbarNavItem
        renderProps={(props: any) => (
          <NavLink
            to={appRoutes.CUSTOMERS_SETTINGS}
            {...props}
            component={NavbarNavItemLink}
          />
        )}
      >
        <Icon name="cogs" />
        <FormattedMessage id="Settings" defaultMessage="Settings" />
      </NavbarNavItem>
    </ProtectedComponent>
    <ProtectedComponent resource={PermissionsEnum.SETTINGS_MANAGE}>
      <NavbarNavItem
        renderProps={(props: any) => (
          <NavLink
            to={appRoutes.SETTINGS}
            {...props}
            component={NavbarNavItemLink}
          />
        )}
      >
        <Icon name="cogs" />
        <FormattedMessage id="Settings" defaultMessage="Settings" />
      </NavbarNavItem>
    </ProtectedComponent>
  </NavbarNav>
);
