import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
`;
