import { useIntl } from 'react-intl';
import { FieldPath, Controller } from 'react-hook-form';
import { TextInput, FormGroup } from '@billon/ui';

// Types
import { TransactionFieldProps } from '../types';

export function IBAN<T>({
  errors,
  control,
  isLoading,
  countryCode,
  disabled = false,
  name = 'iban',
  namePrefix = '',
}: TransactionFieldProps<T>) {
  const { formatMessage } = useIntl();

  return (
    <FormGroup>
      <Controller
        name={`${namePrefix}${name}` as FieldPath<T>}
        render={({ field }) => (
          <TextInput
            {...field}
            errors={errors}
            disabled={isLoading || disabled}
            placeholder={formatMessage({
              id: 'Place account number here',
              defaultMessage: 'Place account number here',
            })}
            label={formatMessage({
              id: 'Bank account number (IBAN)',
              defaultMessage: 'Bank account number (IBAN)',
            })}
            size="lg"
            prefix={countryCode}
            onChange={(e: any) => {
              if (!e.target) {
                field.onChange(e);
                return;
              }

              if (countryCode) {
                const value = e.target.value;

                if (value.length <= countryCode.length) {
                  field.onChange(countryCode);
                  return;
                }

                field.onChange(
                  countryCode +
                    value
                      .replace(/\s/g, '')
                      .replace(new RegExp(countryCode, 'g'), ''),
                );
              }
            }}
            mask={/^[A-Z0-9 ]*$/}
          />
        )}
        control={control}
      />
    </FormGroup>
  );
}
