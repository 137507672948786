import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { IBANValidator, SortCodeValidator } from '@billon/validators';
import { TransactionDetailsFieldsEnum } from '@billon/utils';

import { FormErrorsEnum } from 'utils/constants';

export const useTransactionDetailsSchema = () => {
  const { formatMessage } = useIntl();

  const requiredShape = yup.string().required(
    formatMessage({
      id: FormErrorsEnum.REQUIRED,
      defaultMessage: FormErrorsEnum.REQUIRED,
    }),
  );

  const setFieldValidation = (field: TransactionDetailsFieldsEnum) => {
    switch (field) {
      case TransactionDetailsFieldsEnum.IBAN:
        return requiredShape.test(
          'iban',
          formatMessage({
            id: FormErrorsEnum.INVALID_IBAN,
            defaultMessage: FormErrorsEnum.INVALID_IBAN,
          }),
          (value) => {
            const validator = new IBANValidator();
            return value ? validator.validate(value) : true;
          },
        );

      case TransactionDetailsFieldsEnum.SORT_CODE:
        return requiredShape.test(
          'sortCode',
          formatMessage({
            id: FormErrorsEnum.INVALID_SORT_CODE,
            defaultMessage: FormErrorsEnum.INVALID_SORT_CODE,
          }),
          (value) => {
            const validator = new SortCodeValidator();
            return value ? validator.validate(value) : true;
          },
        );

      case TransactionDetailsFieldsEnum.BIC:
        return requiredShape;

      case TransactionDetailsFieldsEnum.ACCOUNT_NUMBER:
        return requiredShape;

      case TransactionDetailsFieldsEnum.ROUTING_CODE:
        return requiredShape;
    }
  };

  const getTransactionDetailsSchema = (
    fields: TransactionDetailsFieldsEnum[] = [],
  ) => {
    const schema = {};

    fields.forEach((field) => {
      schema[field] = setFieldValidation(field);
    });

    return yup.object().shape(schema);
  };

  return getTransactionDetailsSchema;
};
