import { useIntl } from 'react-intl';
import { SelectOptionProps } from '@billon/ui';

import { useCountryRequest } from './useCountryRequest';

export const useUserAvailableCountriesOptions = () => {
  const { formatMessage } = useIntl();
  const { data: countries = [], isFetching } = useCountryRequest();

  const countriesOptions: SelectOptionProps[] = countries.map(
    ({ countryCode, label }) => ({
      label: formatMessage({
        id: label,
        defaultMessage: label,
      }),
      value: countryCode,
    }),
  );

  return { countriesOptions, isFetching };
};
