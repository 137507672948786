import { CorporateAccountThemeInterface } from 'components';
import { useTheme } from 'styled-components';

import {
  PayoutCollectionDefaultSettingsEnum,
  PayoutCollectionPageBackgroundType,
  PayoutCollectionPageHeaderType,
} from 'corporate-account-shared';

import { useDefaultPayoutCollectionProperties } from './useDefaultPayoutCollectionProperties';

export interface CustomerPayoutCollectionPropertiesInterface {
  payoutSMSNotificationMessage: string;
  collectionPageBackgroundType: PayoutCollectionPageBackgroundType;
  collectionPageHeaderType: PayoutCollectionPageHeaderType;
  collectionPageWebTitle: PayoutCollectionDefaultSettingsEnum | string;
  collectionPageTitle: PayoutCollectionDefaultSettingsEnum | string;
  collectionPageHeaderLink: string;
  collectionPageSupportPageLink: PayoutCollectionDefaultSettingsEnum | string;
  collectionPageSupportPageLinkText:
    | PayoutCollectionDefaultSettingsEnum
    | string;
  collectionPageNoPayoutsMessage: PayoutCollectionDefaultSettingsEnum | string;
  collectionPageSelectCurrencyTitle:
    | PayoutCollectionDefaultSettingsEnum
    | string;
  collectionPageSuccessMessage: PayoutCollectionDefaultSettingsEnum | string;
  collectionPageFavicon: string;
  collectionPageBackgroundColor: string;
  collectionPageBackgroundImage: string;
  collectionPageLogo: string;
}

export const useCustomerPayoutCollectionProperties =
  (): CustomerPayoutCollectionPropertiesInterface => {
    const customerProperties = useTheme() as CorporateAccountThemeInterface;
    const defaultProperties = useDefaultPayoutCollectionProperties();

    return {
      payoutSMSNotificationMessage:
        customerProperties.payoutSMSNotificationMessage || '',
      collectionPageBackgroundType:
        customerProperties.collectionPageBackgroundType ||
        defaultProperties.collectionPageBackgroundType,
      collectionPageHeaderType:
        customerProperties.collectionPageHeaderType ||
        defaultProperties.collectionPageHeaderType,
      collectionPageWebTitle:
        customerProperties.collectionPageWebTitle ||
        defaultProperties.collectionPageWebTitle,
      collectionPageTitle:
        customerProperties.collectionPageTitle ||
        defaultProperties.collectionPageTitle,
      collectionPageHeaderLink:
        customerProperties.collectionPageHeaderLink || '',
      collectionPageSupportPageLinkText:
        customerProperties.collectionPageSupportPageLinkText ||
        defaultProperties.collectionPageSupportPageLinkText,
      collectionPageSupportPageLink:
        customerProperties.collectionPageSupportPageLink ||
        defaultProperties.collectionPageSupportPageLink,
      collectionPageNoPayoutsMessage:
        customerProperties.collectionPageNoPayoutsMessage ||
        defaultProperties.collectionPageNoPayoutsMessage,
      collectionPageSelectCurrencyTitle:
        customerProperties.collectionPageSelectCurrencyTitle ||
        defaultProperties.collectionPageSelectCurrencyTitle,
      collectionPageSuccessMessage:
        customerProperties.collectionPageSuccessMessage ||
        defaultProperties.collectionPageSuccessMessage,

      collectionPageFavicon: customerProperties.collectionPageFavicon || '',
      collectionPageBackgroundColor:
        customerProperties.collectionPageBackgroundColor || '',
      collectionPageBackgroundImage:
        customerProperties.collectionPageBackgroundImage || '',
      collectionPageLogo: customerProperties.collectionPageLogo || '',
    };
  };
