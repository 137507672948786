import { StandardFormField } from '@billon/ui';
import { useIntl } from 'react-intl';
import { FieldPath } from 'react-hook-form';

// Types
import { TransactionFieldProps } from '../types';

export function BIC<T>({
  errors,
  control,
  isLoading,
  disabled = false,
  name = 'BIC',
  namePrefix = '',
}: TransactionFieldProps<T>) {
  const { formatMessage } = useIntl();
  return (
    <StandardFormField<T>
      control={control}
      isLoading={isLoading}
      disabled={disabled}
      errors={errors}
      name={`${namePrefix}${name}` as FieldPath<T>}
      placeholder={formatMessage({
        id: 'Place bank identifier code here',
        defaultMessage: 'Place bank identifier code here',
      })}
      label={formatMessage({ id: 'BIC/SWIFT', defaultMessage: 'BIC/SWIFT' })}
    />
  );
}
