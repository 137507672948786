import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== 'production') {
  const originalConsoleError = console.error;
  if (console.error === originalConsoleError) {
    console.error = (...args: any[]) => {
      if (
        args[0]
          .toString()
          .indexOf('[@formatjs/intl Error MISSING_TRANSLATION]') !== -1
      ) {
        return;
      }
      originalConsoleError.call(console, ...args);
    };
  }
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
