import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Icon,
  NavbarDropdownToggler,
  useAuthContext,
} from '@billon/ui';

// Utils
import { appRoutes } from 'appRoutes';

const StyledUserNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledUserName = styled.div`
  display: flex;
  gap: 0.25rem;
  font-weight: ${({ theme }) => theme.fontWeightBase};
  align-items: center;

  strong {
    font-weight: ${({ theme }) => theme.fontWeightMedium};
  }
`;

const StyledSessionLeft = styled.div`
  font-size: 0.7rem;
  font-weight: ${({ theme }) => theme.fontWeightBase};

  strong {
    font-weight: ${({ theme }) => theme.fontWeightMedium};
  }
`;

export const LoggedInUserInfo = () => {
  const { push } = useHistory();
  const queryClient = useQueryClient();
  const { user, handleLogout } = useAuthContext();
  const [sessionLeft, setSessionLeft] = useState('0');

  const handleSignOut = () => {
    queryClient.removeQueries();
    handleLogout();
    push(appRoutes.HOME_PAGE_PANEL);
  };

  useEffect(() => {
    let interval;
    if (user) {
      interval = setInterval(() => {
        const difference = DateTime.fromISO(user.expiresIn).diff(
          DateTime.utc(),
        );

        setSessionLeft(difference.toFormat('mm:ss'));
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [user]);

  return (
    <Dropdown
      trigger={
        <NavbarDropdownToggler>
          <Avatar text={`${user.firstName} ${user.lastName}`} />
          <StyledUserNameContainer>
            <StyledUserName>
              <FormattedMessage id="Welcome" defaultMessage="Welcome" />,{' '}
              <strong>
                {user.firstName} {user.lastName}
              </strong>
              <Icon name="chevron-down" size="xs" />
            </StyledUserName>
            {sessionLeft !== '0' && (
              <StyledSessionLeft>
                <FormattedMessage
                  id="Session left"
                  defaultMessage="Session left"
                />
                : <strong>{sessionLeft}</strong>
              </StyledSessionLeft>
            )}
          </StyledUserNameContainer>
        </NavbarDropdownToggler>
      }
      position="bottom right"
    >
      {() => (
        <DropdownMenu>
          <DropdownItem onClick={handleSignOut}>
            <Icon name="power-off" />
            <FormattedMessage id="Sign out" defaultMessage="Sign out" />
          </DropdownItem>
        </DropdownMenu>
      )}
    </Dropdown>
  );
};
