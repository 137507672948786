import { StandardFormField } from '@billon/ui';
import { useIntl } from 'react-intl';
import { FieldPath } from 'react-hook-form';

// Types
import { TransactionFieldProps } from '../types';

export function SortCode<T>({
  errors,
  control,
  isLoading,
  disabled = false,
  name = 'sortCode',
  namePrefix = '',
}: TransactionFieldProps<T>) {
  const { formatMessage } = useIntl();
  return (
    <StandardFormField<T>
      control={control}
      isLoading={isLoading}
      disabled={disabled}
      errors={errors}
      name={`${namePrefix}${name}` as FieldPath<T>}
      placeholder={formatMessage({
        id: 'Place sort code here',
        defaultMessage: 'Place sort code here',
      })}
      label={formatMessage({
        id: 'Sort code',
        defaultMessage: 'Sort code',
      })}
    />
  );
}
