import styled from 'styled-components';
import { Card } from '@billon/ui';

export interface AccountPreviewProps {
  children: React.ReactNode;
  borderColor: 'primary' | 'secondary';
}

const StyledCard = styled(Card)<{ borderColor: 'primary' | 'secondary' }>`
  border-left: 5px solid
    ${({ theme, borderColor }) => theme.palette[borderColor]};
  margin-bottom: 2rem;
`;

export const AccountPreview = ({
  children,
  borderColor,
}: AccountPreviewProps) => (
  <StyledCard borderColor={borderColor}>{children}</StyledCard>
);
