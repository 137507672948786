import styled, { useTheme } from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { Button, Icon } from '@billon/ui';
import { CorporateAccountThemeInterface } from 'components/ThemeProvider';

export interface BackButtonProps {
  onBack: () => void;
}

const StyledBackButton = styled<typeof Button>(Button)`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizeBase};

  svg {
    margin-right: 0.75rem;
  }
`;

export const BackButton = ({ onBack }: BackButtonProps) => {
  const theme = useTheme() as CorporateAccountThemeInterface;

  return (
    <StyledBackButton onClick={onBack} size="lg" noPadding>
      <Icon name="angle-left" color={theme.palette.primary} />
      <FormattedMessage id="Go back" defaultMessage="Go back" />
    </StyledBackButton>
  );
};
