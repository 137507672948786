import { Link } from 'react-router-dom';
import { Navbar, BillonLogo, useAuthContext, ChangeLanguage } from '@billon/ui';

// Utils
import { appRoutes } from 'appRoutes';

// Components
import { CustomerLogo } from 'components/CustomerLogo';
import { LoggedInUserInfo, Menu } from './components';

export const Header = () => {
  const { user } = useAuthContext();

  return (
    <Navbar fluid>
      <Link to={appRoutes.HOME_PAGE_PANEL}>
        {user.customer?.logoUrl ? (
          <CustomerLogo src={user.customer.logoUrl} maxHeight="4rem" />
        ) : (
          <BillonLogo />
        )}
      </Link>
      <Menu />
      <ChangeLanguage />
      <LoggedInUserInfo />
    </Navbar>
  );
};
