import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Icon } from '@billon/ui';

const StyledNoAccounts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  margin: 10rem;
  font-size: ${({ theme }) => theme.fontSizeLg};
`;

const StyledIcon = styled<typeof Icon>(Icon)`
  font-size: 5rem;
  color: ${({ theme }) => theme.palette.gray};
`;

export const NoAccountsInfo = () => (
  <StyledNoAccounts>
    <StyledIcon name="user-slash" />
    <FormattedMessage
      id="You do not have any accounts."
      defaultMessage="You do not have any accounts."
    />
  </StyledNoAccounts>
);
