import { useState, useEffect, ReactNode, useMemo } from 'react';
import { matchPath } from 'react-router';
import {
  buildTheme,
  getLightenDarkenColor,
  ThemeProvider,
  useAuthContext,
  useConfigContext,
} from '@billon/ui';

import config from 'config';
import { usePayoutCollectionTemplatePropertiesRequest } from 'hooks';
import { appRoutes } from 'appRoutes';

export interface CorporateAccountThemeProviderProps {
  children: (url?: string) => ReactNode;
}

const computeThemeFromTemplate = (
  theme,
  {
    primaryColor,
    logoUrl,
    PAYOUT_SMS_NOTIFICATION_MESSAGE,
    PAYOUT_COLLECTION_PAGE_WEB_TITLE,
    PAYOUT_COLLECTION_PAGE_FAVICON,
    PAYOUT_COLLECTION_PAGE_BACKGROUND_TYPE,
    PAYOUT_COLLECTION_PAGE_BACKGROUND_IMAGE,
    PAYOUT_COLLECTION_PAGE_BACKGROUND_COLOR,
    PAYOUT_COLLECTION_PAGE_HEADER_TYPE,
    PAYOUT_COLLECTION_PAGE_TITLE,
    PAYOUT_COLLECTION_PAGE_LOGO,
    PAYOUT_COLLECTION_PAGE_HEADER_LINK,
    PAYOUT_COLLECTION_PAGE_SUPPORT_PAGE_LINK_TEXT,
    PAYOUT_COLLECTION_PAGE_SUPPORT_PAGE_LINK,
    PAYOUT_COLLECTION_PAGE_NO_PAYUOTS_MESSAGE,
    PAYOUT_COLLECTION_PAGE_SELECT_PAYOUTS_TITLE,
    PAYOUT_COLLECTION_PAGE_SUCCESS_MESSAGE,
    PAYIN_BARCODE_DETAILS_PAGE_LOGO,
    PAYIN_BARCODE_DETAILS_PAGE_INSTRUCTION_LINK,
    PAYIN_BARCODE_DETAILS_PAGE_SUPPORT_PAGE_LINK_TEXT,
    PAYIN_BARCODE_DETAILS_PAGE_SUPPORT_PAGE_LINK,
    PAYIN_BARCODE_DETAILS_PAGE_POINTS_RANKING_PAGE_LINK_TEXT,
    PAYIN_BARCODE_DETAILS_PAGE_POINTS_RANKING_PAGE_LINK,
    CUSTOM_DOMAIN_NAME,
  },
) => {
  return {
    ...theme,
    palette: {
      ...theme.palette,
      ...(primaryColor
        ? {
            primary: primaryColor,
            primaryLight: getLightenDarkenColor(primaryColor, 30),
            primaryLightest: getLightenDarkenColor(primaryColor, 50),
            primaryDark: getLightenDarkenColor(primaryColor, -30),
          }
        : {}),
    },
    logoUrl,
    payoutSMSNotificationMessage: PAYOUT_SMS_NOTIFICATION_MESSAGE,
    collectionPageWebTitle: PAYOUT_COLLECTION_PAGE_WEB_TITLE,
    collectionPageFavicon: PAYOUT_COLLECTION_PAGE_FAVICON,
    collectionPageBackgroundType: PAYOUT_COLLECTION_PAGE_BACKGROUND_TYPE,
    collectionPageBackgroundImage: PAYOUT_COLLECTION_PAGE_BACKGROUND_IMAGE,
    collectionPageBackgroundColor: PAYOUT_COLLECTION_PAGE_BACKGROUND_COLOR,
    collectionPageHeaderType: PAYOUT_COLLECTION_PAGE_HEADER_TYPE,
    collectionPageTitle: PAYOUT_COLLECTION_PAGE_TITLE,
    collectionPageLogo: PAYOUT_COLLECTION_PAGE_LOGO,
    collectionPageHeaderLink: PAYOUT_COLLECTION_PAGE_HEADER_LINK,
    collectionPageSupportPageLinkText:
      PAYOUT_COLLECTION_PAGE_SUPPORT_PAGE_LINK_TEXT,
    collectionPageSupportPageLink: PAYOUT_COLLECTION_PAGE_SUPPORT_PAGE_LINK,
    collectionPageNoPayoutsMessage: PAYOUT_COLLECTION_PAGE_NO_PAYUOTS_MESSAGE,
    collectionPageSelectCurrencyTitle:
      PAYOUT_COLLECTION_PAGE_SELECT_PAYOUTS_TITLE,
    collectionPageSuccessMessage: PAYOUT_COLLECTION_PAGE_SUCCESS_MESSAGE,
    payinBarCodeDetailsInstructionLink:
      PAYIN_BARCODE_DETAILS_PAGE_INSTRUCTION_LINK,
    payinBarCodeDetailsSupportPageLinkText:
      PAYIN_BARCODE_DETAILS_PAGE_SUPPORT_PAGE_LINK_TEXT,
    payinBarCodeDetailsSupportPageLink:
      PAYIN_BARCODE_DETAILS_PAGE_SUPPORT_PAGE_LINK,
    payinBarCodeDetailsPointsRankingPageLinkText:
      PAYIN_BARCODE_DETAILS_PAGE_POINTS_RANKING_PAGE_LINK_TEXT,
    payinBarCodeDetailsPointsRankingPageLink:
      PAYIN_BARCODE_DETAILS_PAGE_POINTS_RANKING_PAGE_LINK,
    payinBarCodeDetailsLogo: PAYIN_BARCODE_DETAILS_PAGE_LOGO,
    customDomainName: CUSTOM_DOMAIN_NAME,
  };
};

export const CorporateAccountThemeProvider = ({
  children,
}: CorporateAccountThemeProviderProps) => {
  const { user } = useAuthContext();
  const { getUploadsPath, defaultClient } = useConfigContext();
  const { pathname } = window.location;

  const matchPanel = matchPath<{ url?: string }>(pathname, {
    path: `/:url?${appRoutes.HOME_PAGE_PANEL}`,
  });
  const matchBarCodeView = matchPath<{ url?: string }>(pathname, {
    path: `/:url?${appRoutes.PAYIN_BARCODE_VIEW.replace('/:barcode', '')}`,
  });
  const matchCollection = matchPath<{ url?: string; projectUrl?: string }>(
    pathname,
    {
      path: `/:url?${appRoutes.PAYOUT_COLLECTION}:projectUrl?`,
      exact: true,
    },
  );

  let computedUrl =
    matchPanel?.params.url ||
    matchBarCodeView?.params.url ||
    matchCollection?.params.url ||
    '';

  if (
    computedUrl &&
    ['panel', 'token-expired', 'barcode'].includes(computedUrl)
  ) {
    computedUrl = '';
  }

  const [url, setUrl] = useState<string>(computedUrl);

  const { isLoading, data } = usePayoutCollectionTemplatePropertiesRequest({
    customerUrl: defaultClient || url,
    projectUrl:
      !matchPanel && !matchBarCodeView
        ? matchCollection?.params.projectUrl
        : undefined,
  });

  const theme = useMemo(() => {
    if (isLoading || !data) {
      return config.theme;
    }

    return computeThemeFromTemplate(config.theme, data);
  }, [config.theme, data]);

  useEffect(() => {
    if (isLoading || !data) {
      return;
    }
    const {
      PAYOUT_COLLECTION_PAGE_WEB_TITLE,
      PAYOUT_COLLECTION_PAGE_FAVICON,
      PAYIN_BARCODE_DETAILS_PAGE_WEB_TITLE,
      PAYIN_BARCODE_DETAILS_PAGE_FAVICON,
    } = data;
    if (
      !pathname.includes(appRoutes.HOME_PAGE_PANEL) &&
      !pathname.includes(appRoutes.TOKEN_EXPIRED) &&
      !pathname.includes('barcode')
    ) {
      if (
        PAYOUT_COLLECTION_PAGE_WEB_TITLE &&
        PAYOUT_COLLECTION_PAGE_WEB_TITLE !== 'Corporate Account Panel'
      ) {
        document.title = PAYOUT_COLLECTION_PAGE_WEB_TITLE;
      }

      if (PAYOUT_COLLECTION_PAGE_FAVICON) {
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        favicon.href = getUploadsPath?.(
          PAYOUT_COLLECTION_PAGE_FAVICON,
        ) as string;
      }
    }

    if (pathname.includes('barcode')) {
      if (
        PAYIN_BARCODE_DETAILS_PAGE_WEB_TITLE &&
        PAYIN_BARCODE_DETAILS_PAGE_WEB_TITLE !== 'Corporate Account Panel'
      ) {
        document.title = PAYIN_BARCODE_DETAILS_PAGE_WEB_TITLE;
      }

      if (PAYIN_BARCODE_DETAILS_PAGE_FAVICON) {
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        favicon.href = getUploadsPath?.(
          PAYIN_BARCODE_DETAILS_PAGE_FAVICON,
        ) as string;
      }
    }
  }, [data, isLoading, pathname]);

  useEffect(() => {
    if (user && user.customer?.url) {
      setUrl(user.customer.url);
    }
  }, [user]);

  return (
    <ThemeProvider theme={buildTheme(theme)}>{children(url)}</ThemeProvider>
  );
};
