import styled from 'styled-components';

export const RadioGroupOption = styled.div<{
  selected: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primaryLightest : theme.palette.grayLightest};
  padding: 1rem;
  margin-top: 0.5rem;
  transition: ${({ theme }) => theme.transitionBase};
  cursor: ${({ disabled, theme }) =>
    disabled ? theme.disabledCursor : 'pointer'};

  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme.palette.primaryLightest : theme.palette.grayLighter};
  }

  h4 {
    margin: 0 0 0 1rem;
    font-weight: ${({ theme }) => theme.fontWeightBold};
  }
`;
