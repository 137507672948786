import { useQuery } from 'react-query';

import { apiRoutes } from 'apiRoutes';
import { QueryKeysEnum } from 'utils/constants';
import { getRequest } from 'helpers';

export const useCustomerRequest = (id?: string | null) => {
  return useQuery(
    [QueryKeysEnum.CUSTOMER_REQUEST, id],
    () => getRequest(apiRoutes.SINGLE_CUSTOMER.replace('{id}', `${id}`)),
    { enabled: !!id },
  );
};
