import styled from 'styled-components';
import { useConfigContext } from '@billon/ui';

export interface CustomerLogoProps {
  src: string;
  maxHeight?: string;
  maxWidth?: string;
}

const StyledCustomerLogo = styled.img<CustomerLogoProps>`
  max-height: ${({ maxHeight }) => maxHeight || '20rem'};
  max-width: ${({ maxWidth }) => maxWidth || '20rem'};
`;

export const CustomerLogo = ({
  src,
  maxHeight,
  maxWidth,
}: CustomerLogoProps) => {
  const { getUploadsPath } = useConfigContext();
  return (
    <StyledCustomerLogo
      src={getUploadsPath?.(src) as string}
      alt="Customer logo"
      maxHeight={maxHeight}
      maxWidth={maxWidth}
    />
  );
};
