import styled from 'styled-components';

export const DetailsList = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  gap: 2rem;
  flex-wrap: wrap;

  li {
    label {
      display: block;
      font-weight: ${({ theme }) => theme.fontWeightBold};
    }
  }
`;
