import styled from 'styled-components';
import { PoweredBy } from '@billon/ui';

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.palette.grayDarker};
  padding: 4rem 0;
`;

const StyledPoweredBy = styled(PoweredBy)`
  justify-content: center;
  width: 100%;
`;

export const Footer = () => (
  <StyledFooter>
    <StyledPoweredBy color="white" />
  </StyledFooter>
);
